import React, {useEffect, useState} from 'react';
import {Container, Navbar} from 'react-bootstrap';
import {Route} from 'react-router-dom';
import './style.css';
import {APP_KEY, goAdminUrl, storageGet} from '../../utils';

const SecureRouter = ({component: Component, ...rest}) => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (storageGet(APP_KEY))
      goAdminUrl('dashboard');
    else
      setLoad(true);
  }, []);
  return load && <Route {...rest} render={matchProps => (
    <>
      <Navbar bg="light" variant="light" className="shadow-sm">
        <Container className="justify-content-center">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt={'Sky Yacht Charter'} height={'25'}/> SkyYachtCharter
        </Container>
      </Navbar>
      <br/>
      <Component {...matchProps} />
    </>
  )}/>;
};

export {SecureRouter};