import React from 'react';
import { createRoot } from "react-dom/client"
import {Router, Switch} from 'react-router-dom';
import {history,strings} from 'utils';
import './style.css';

import {SecureRouter} from './context/secure';
import {AdminRouter} from './context/admin';
import {Login} from './view/secure/login';
import {Dashboard} from './view/admin/dashboard';
import {Yacht} from './view/admin/yacht';
import {Routes} from './view/admin/routes';
import {YachtForm} from './view/admin/yachtForm';
import {RoutesForm} from './view/admin/routesForm';
import {YachtBanner} from './view/admin/yachtBanner';
import {YachtGallery} from './view/admin/yachtGallery';

const App = () => {
  let adminPrefix = strings.PATH.ADMIN;

  return <>
    <Router history={history}>
      <Switch>
        <SecureRouter exact path={'/'} component={Login}/>
        <AdminRouter exact path={adminPrefix + '/dashboard'} component={Dashboard}/>
        <AdminRouter exact path={adminPrefix + '/yacht'} component={Yacht}/>
        <AdminRouter exact path={adminPrefix + '/yacht/banner/:id'} component={YachtBanner}/>
        <AdminRouter exact path={adminPrefix + '/yacht/gallery/:id'} component={YachtGallery}/>
        <AdminRouter exact path={adminPrefix + '/yacht/new'} component={YachtForm}/>
        <AdminRouter exact path={adminPrefix + '/yacht/:id'} component={YachtForm}/>
        <AdminRouter exact path={adminPrefix + '/routes'} component={Routes}/>
        <AdminRouter exact path={adminPrefix + '/routes/new'} component={RoutesForm}/>
        <AdminRouter exact path={adminPrefix + '/routes/:id'} component={RoutesForm}/>
      </Switch>
    </Router>
  </>;
};

const root = createRoot(document.getElementById('root'));

root.render(<App/>);

