import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Container, Row} from 'react-bootstrap';
import {Connector, ConnectorForm} from '../../utils/axios';
import {goAdminUrl} from '../../utils';
import FileUpload from '../../components/fileUpload';

const FileView = (props) => {
  return <div className="mb-3">
    <img src={`${process.env.REACT_APP_FILE_URL}/${props.name}`} alt="pictures" className="previewImageSingle"/>
    <div className="previewImageSingleRemove" onClick={props.onClick}>Remove</div>
  </div>;
};

const YachtGallery = (props) => {
  const [formID] = useState(props.match?.params?.id ?? null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [maxFileSize, setMaxFileSize] = useState(20);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    if (formID) {
      Connector.get(`/image/pictures/${formID}`)
        .then(res => {
          //View: `${process.env.REACT_APP_FILE_URL}/filename.jpg`
          setUploadedFiles(res.data.data);
          const uploadedFilesCount = res.data.data.length ?? 0;
          setMaxFileSize(20 - uploadedFilesCount);
        })
        .catch(e => {
          setUploadedFiles([]);
          setMaxFileSize(20);
        });
    }
  }, [formID]);

  const onSubmitUpload = () => {
    setErrorMessage(null);
    setIsLoading(true);
    if (formData && formData.length > 0) {
      let _formData = new FormData();
      formData.forEach(item => _formData.append('pictures', item));
      ConnectorForm.put(`/image/pictures/${formID}`, _formData)
        .then(res => {
          setIsLoading(false);
          goAdminUrl(`yacht/gallery/${formID}`);
        })
        .catch(err => {
          setErrorMessage(err.response?.data?.data);
          setIsLoading(false);
        });
    } else {
      setErrorMessage('No Image Selected');
      setIsLoading(false);
    }
  };
  const onSubmitRemove = (id) => {
    Connector.delete(`/image/pictures/${id}`)
      .then(resRemove => {
        Connector.get(`/image/pictures/${formID}`)
          .then(res => {
            //View: `${process.env.REACT_APP_FILE_URL}/filename.jpg`
            setUploadedFiles(res.data.data);
            const uploadedFilesCount = res.data.data.length ?? 0;
            setMaxFileSize(20 - uploadedFilesCount);
          })
          .catch(e => {
            setUploadedFiles([]);
            setMaxFileSize(20);
          });
      })
      .catch(err => {
        setErrorMessage(err.response?.data?.data);
      });
  };

  return <Container>
    <div className="pageBrach">
      <h3>Edit Gallery</h3>
    </div>

    <Row>
      <Col md={{span: 6}}>
        <Card body>
          {uploadedFiles.map(item => <FileView name={item.name} onClick={()=>onSubmitRemove(item.id)}/>)}
        </Card>
      </Col>
      <Col md={{span: 6}}>
        <Card body>
          <div className="formErrorMessage">{errorMessage}</div>
          <FileUpload
            maxFile={maxFileSize}
            onChange={val => setFormData(val)}
          />
          <div className="text-center mt-2">
            <Button onClick={onSubmitUpload} disabled={isLoading}>Upload New Files</Button>
          </div>
        </Card>
      </Col>
    </Row>
  </Container>;
};

export {YachtGallery};