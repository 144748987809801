import axios from 'axios';
import {storageGet, storageRemove} from './storage';
import {APP_KEY} from './index';

axios.defaults.headers = {
  'Access-Control-Allow-Origin': '**',
  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
  'Content-Type': 'application/json'
};
export const Connector = axios.create({
  'baseURL': process.env.REACT_APP_API_URL,
  'headers': {'authorization': `Bearer ${storageGet(APP_KEY).token}`}
});
export const ConnectorForm = axios.create({
  'baseURL': process.env.REACT_APP_API_URL,
  'headers': {
    'Content-Type': 'multipart/form-data',
    'authorization': `Bearer ${storageGet(APP_KEY).token}`
  }
});

Connector.interceptors.response.use(
  response => response,
  error => {
    if (401 === error.response.status) {
      storageRemove(APP_KEY);
      window.location.href = '/';
    } else {
      return Promise.reject(error);
    }
  }
);
ConnectorForm.interceptors.response.use(
  response => response,
  error => {
    if (401 === error.response.status) {
      storageRemove(APP_KEY);
      window.location.href = '/';
    } else {
      return Promise.reject(error);
    }
  }
);