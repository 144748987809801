import React, {useEffect, useState} from 'react';
import {Button, Card, Container, Form, Table} from 'react-bootstrap';
import {goAdminUrl} from '../../utils';
import {Icon} from '../../components/Icon';
import {Connector} from '../../utils/axios';
import {DatatableWrapper, Pagination, TableBody, TableHeader} from 'react-bs-datatable';

const Yacht = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Connector.get('/vehicle')
      .then(res => {
        setData(res.data.data);
      })
      .catch(e => {
        console.log(e.toString());
      });
  }, []);

  const tableColumn = [
    {
      prop: 'button',
      title: '#',
      thProps: {
        width: '20px'
      },
      cell: (row) => <># </>
    },
    {
      prop: 'name',
      title: 'Name'
    },
    {
      prop: 'location',
      title: 'Location'
    },
    {
      prop: 'button',
      title: 'Publish',
      thProps: {
        width: '50px'
      },
      cell: (row) => <Form.Check // prettier-ignore
        type="switch"
        id="custom-switch"
        checked={parseInt(row.publish)}
        onChange={async e => {
          await Connector.post(`/vehicle/show`, {
            id: row.id,
            publish: e.target.checked ? 1 : 0
          });
          Connector.get('/vehicle')
            .then(res => {
              setData(res.data.data);
            })
            .catch(e => {
              console.log(e.toString());
            });
        }}
      />
    },
    {
      prop: 'button',
      title: <Icon item="bolt"/>,
      thProps: {
        width: '120px'
      },
      cell: (row) => <>
        <Icon item="picture-o pointer postIcon" onClick={() => goAdminUrl(`yacht/banner/${row.id}`)}/>
        <Icon item="file-image-o pointer postIcon" onClick={() => goAdminUrl(`yacht/gallery/${row.id}`)}/>
        <Icon item="edit pointer postIcon" onClick={() => goAdminUrl(`yacht/${row.id}`)}/>
      </>
    }
  ];

  return <Container>
    <div className="pageBrach">
      <h3>Yachts</h3>
      <Button onClick={() => goAdminUrl('yacht/new')}>New</Button>
    </div>
    <Card body>
      <DatatableWrapper
        body={data}
        headers={tableColumn}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10
          }
        }}>
        <Table>
          <TableHeader/>
          <TableBody/>
        </Table>
        <div className="text-end">
          <Pagination/>
        </div>
      </DatatableWrapper>
    </Card>
  </Container>;
};

export {Yacht};