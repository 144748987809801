import React, {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import PropTypes from 'prop-types';

const style = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  marginTop: '20px'
};
const FileUpload = ({onChange, maxFile}) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(p => {
      const data = [...p, ...acceptedFiles];
      onChange(data);
      return data;
    });
  }, [onChange]);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true,
    maxFiles: maxFile
  });

  return <>
    <div {...getRootProps()} style={style}>
      <input {...getInputProps()} />
      <p style={{marginTop: '1rem'}}>{isDragActive ? 'Drop It' : 'Drag & Drop OR Click'}</p>
    </div>
    {
      files.length > 0 && <div className="fileUploadList">
        <h4>Files:</h4>
        <ul>
          {files.map(file => <li key={file.name}>{file.name}</li>)}
        </ul>
      </div>
    }
  </>;
};

FileUpload.propTypes = {
  maxFile: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

FileUpload.defaultProps = {
  maxFile: 20
};

export default FileUpload;