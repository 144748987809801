import React, {useEffect, useState} from 'react';
import {Button, Card, Container, Table} from 'react-bootstrap';
import {goAdminUrl} from '../../utils';
import {Icon} from '../../components/Icon';
import {Connector} from '../../utils/axios';
import {DatatableWrapper, Pagination, TableBody, TableHeader} from 'react-bs-datatable';

const Routes = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    Connector.get('/routes')
      .then(res => {
        setData(res.data.data);
      })
      .catch(e => {
        console.log(e.toString());
      });
  }, []);

  const tableColumn = [
    {
      prop: 'button',
      title: '#',
      thProps: {
        width: '20px'
      },
      cell: (row) => <># </>
    },
    {
      prop: 'name',
      title: 'Name'
    },
    {
      prop: 'button',
      title: <Icon item="bolt"/>,
      thProps: {
        width: '30px'
      },
      cell: (row) => <>
        <Icon item="edit pointer postIcon" onClick={() => goAdminUrl(`routes/${row.id}`)}/>
      </>
    }
  ];

  return <Container>
    <div className="pageBrach">
      <h3>Routes</h3>
      <Button onClick={() => goAdminUrl('routes/new')}>New</Button>
    </div>
    <Card body>
      <DatatableWrapper
        body={data}
        headers={tableColumn}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10
          }
        }}>
        <Table>
          <TableHeader/>
          <TableBody/>
        </Table>
        <div className="text-end">
          <Pagination/>
        </div>
      </DatatableWrapper>
    </Card>
  </Container>;
};

export {Routes};