import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Container, Row} from 'react-bootstrap';
import {goAdminUrl, objectToArray} from '../../utils';
import {Connector} from '../../utils/axios';
import Input from '../../components/input';
import Select from '../../components/select';
import _ from 'lodash';

const initialForm = {
  'name': '',
  'description': '',
  'length': '',
  'year': '',
  'cabinCount': '',
  'maxSize': '',
  'location': '',
  'type': '',
  'model': '',
  'speed': '',
  'routes': [],
  'price': {
    'daily': '',
    'weekly': '',
    'monthly': ''
  }
};

const YachtForm = (props) => {
  const [formID] = useState(props.match?.params?.id ?? null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialForm);
  const [dataRoutes, setDataRoutes] = useState([]);
  const formTitle = formID ? 'Edit Yacht' : 'New Yacht';

  useEffect(() => {
    Connector.get('/routes')
      .then(res => {
        setDataRoutes(res.data.data.map(i => ({
          label: i.name,
          value: i.id
        })));
      })
      .catch(e => {
        setDataRoutes([]);
      });
    if (formID) {
      Connector.get(`/vehicle/${formID}/admin`)
        .then(res => {
          const newFormData = res.data.data;
          newFormData.routes = _.cloneDeep(newFormData).routes.map(i => ({label: i.name, value: i.id}));
          setFormData(newFormData);
        })
        .catch(e => {
          setFormData(initialForm);
        });
    }
  }, [formID]);
  const onSubmitForm = () => {
    setErrorMessage(null);
    setIsLoading(true);
    let error = false;
    objectToArray(formData).forEach(item => {
      if (item.val === '')
        error = true;
    });
    if (error) {
      setErrorMessage('Please Fill Fields');
      setIsLoading(false);
    } else {
      formData.routes = _.cloneDeep(formData.routes).map(i=>i.value)
      if(formID){
        Connector.put(`/vehicle/${formID}`, formData)
          .then(res => {
            setIsLoading(false);
            goAdminUrl('yacht');
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      }else{
        Connector.post(`/vehicle`, formData)
          .then(res => {
            setIsLoading(false);
            goAdminUrl('yacht');
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      }
    }
  };

  return <Container>
    <div className="pageBrach">
      <h3>{formTitle}</h3>
      <Button onClick={onSubmitForm} disabled={isLoading}>Save</Button>
    </div>
    <div className="formErrorMessage">{errorMessage}</div>
    <Card body>
      <Input
        label="Name"
        value={formData.name}
        onChange={e => setFormData({...formData, name: e.target.value})}
      />
      <Input
        label="Description"
        value={formData.description}
        onChange={e => setFormData({...formData, description: e.target.value})}
      />
      <Row>
        <Col md={{span: 6}}>
          <Input
            label="Length"
            value={formData.length}
            onChange={e => setFormData({...formData, length: e.target.value})}
            formProps={{
              type: 'number',
              step: '.01'
            }}
          />
          <Input
            label="Year"
            value={formData.year}
            onChange={e => setFormData({...formData, year: e.target.value})}
            formProps={{
              type: 'number'
            }}
          />
          <Input
            label="Cabin Count"
            value={formData.cabinCount}
            onChange={e => setFormData({...formData, cabinCount: e.target.value})}
            formProps={{
              type: 'number'
            }}
          />
          <Input
            label="Max Size"
            value={formData.maxSize}
            onChange={e => setFormData({...formData, maxSize: e.target.value})}
            formProps={{
              type: 'number'
            }}
          />
          <Input
            label="Location"
            value={formData.location}
            onChange={e => setFormData({...formData, location: e.target.value})}
          />
          <Input
            label="Type"
            value={formData.type}
            onChange={e => setFormData({...formData, type: e.target.value})}
          />
        </Col>
        <Col md={{span: 6}}>
          <Input
            label="Model"
            value={formData.model}
            onChange={e => setFormData({...formData, model: e.target.value})}
          />
          <Input
            label="Speed"
            value={formData.speed}
            onChange={e => setFormData({...formData, speed: e.target.value})}
            formProps={{
              type: 'number'
            }}
          />
          <Select
            label="Routes"
            isMulti
            data={dataRoutes}
            value={formData.routes}
            onChange={e => setFormData({...formData, routes: e})}
          />
          <Input
            label="Price - Daily"
            value={formData.price.daily}
            onChange={e => setFormData({...formData, price: {...formData.price, daily: e.target.value}})}
            formProps={{
              type: 'number'
            }}
          />
          <Input
            label="Price - Weekly"
            value={formData.price.weekly}
            onChange={e => setFormData({...formData, price: {...formData.price, weekly: e.target.value}})}
            formProps={{
              type: 'number'
            }}
          />
          <Input
            label="Price - Monthly"
            value={formData.price.monthly}
            onChange={e => setFormData({...formData, price: {...formData.price, monthly: e.target.value}})}
            formProps={{
              type: 'number'
            }}
          />
        </Col>
      </Row>
    </Card>
  </Container>;
};

export {YachtForm};