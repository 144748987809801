import React, {useEffect, useState} from 'react';
import {Card, Col, Container, ListGroup, Nav, Navbar, Row} from 'react-bootstrap';
import {Route} from 'react-router-dom';
import './style.css';
import {APP_KEY, goAdminUrl, goRoot, logoutUser, storageGet, strings} from 'utils';
import {Icon} from '../../components/Icon';

const AdminRouter = ({component: Component, ...rest}) => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    if (!storageGet(APP_KEY))
      goRoot();
    else
      setLoad(true);
  }, []);

  const menu = [
    {name: 'Yacht', url: 'yacht', icon: 'list'},
    {name: 'Routes', url: 'routes', icon: 'list'}
  ];

  return load && <Route {...rest} render={matchProps => (
    <>
      <Navbar bg="light" variant="light" className="shadow-sm">
        <Container>
          <Navbar.Brand href={strings.PATH.ADMIN + '/dashboard'} className="mr-md-auto">
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt={'Sky Yacht Charter'} height={'25'}/> SkyYachtCharter
          </Navbar.Brand>

          <Nav className="">
            <Nav.Link onClick={() => logoutUser()}>Logout</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <br/>
      <Container>
        <Row>
          <Col md={3}>
            <Card body>
              <ListGroup variant="flush">
                {menu.map(item => <ListGroup.Item className="pointer" onClick={() => goAdminUrl(item.url)}> <Icon
                  item={item.icon}/> {item.name}</ListGroup.Item>)}
              </ListGroup>
            </Card>
          </Col>
          <Col md={9}>
            <Component {...matchProps} />
          </Col>
        </Row>
      </Container>
    </>
  )}/>;
};

export {AdminRouter};