import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import ReactSelect from 'react-select';
import {idSlugify} from '../utils';

const Select = props => {
  return <Form.Group className="mb-3" controlId={idSlugify(props.label)}>
    <Form.Label>{props.label}</Form.Label>
    <ReactSelect
      options={props.data}
      isMulti={props.isMulti}
      value={props.value}
      onChange={e => {
        props.onChange(e);
        return e;
      }}
    />
  </Form.Group>;
};

Select.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool.isRequired,
  formProps: PropTypes.object
};

Select.defaultProps = {
  isMulti: false
};

export default Select;