import React, {useState} from 'react';
import {Button, Card, Col, Container, Row} from 'react-bootstrap';
import {APP_KEY, goAdminUrl, objectToArray, storageSet} from 'utils';
import {Connector} from '../../utils/axios';
import Input from '../../components/input';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [formData, setFormData] = useState({
    mail: '',
    password: ''
  });

  const onSubmitForm = () => {
    setErrorMessage(null);
    let error = false;
    objectToArray(formData).forEach(item => {
      if (item.val === '')
        error = true;
    });
    if (error) {
      setErrorMessage('Please Fill Fields');
    } else {
      Connector.post('/login', formData)
        .then(res => {
          storageSet(APP_KEY, {
            token: res.data?.data?.token
          });
          goAdminUrl('dashboard');
        })
        .catch(err => {
          setErrorMessage(err.response?.data?.data);

          console.log('handleLogin => ', err);
        });
    }
  };

  return <Container>
    <Row>
      <Col md={{span: 6, offset: 3}}>
        <br/>
        <Card body className="padding35">
          <h1 style={{textAlign: 'center', marginBottom: '35px'}}>
            <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Sky Yacht Charter"/>
          </h1>
          <div className="formErrorMessage">{errorMessage}</div>
          <Input
            label="Username"
            value={formData.mail}
            onChange={e => setFormData({...formData, mail: e.target.value})}
          />
          <Input
            label="Password"
            value={formData.password}
            onChange={e => setFormData({...formData, password: e.target.value})}
            formProps={{
              type: 'password'
            }}
          />
          <div className="text-center">
            <Button variant="primary" className="text-center" onClick={onSubmitForm}> Login </Button>
          </div>
        </Card>
      </Col>
    </Row>
  </Container>;
};

export {Login};