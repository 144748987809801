import {APP_KEY, history, storageRemove, strings} from 'utils';

export const historyPush = (path, params = null) => {
  history.push(path, params);
};
export const objectToArray = obj => {
  let tmp = [];
  for (let k in obj) {
    if (obj.hasOwnProperty(k)) {
      tmp.push({
        key: k,
        val: obj[k]
      });
    }
  }
  return tmp;
};
export const trimSpaces = text => {
  return text.replace(/\s+/g, '');
};
export const onlyNumber = str => {
  return str.replace(/[^0-9]+/g, '');
};
export const checkSelectedBox = selected => {
  return selected !== 0;
};
export const sportNameToSportSlug = str => {
  return trimSpaces(str);
};
export const sportSlugToSportName = slug => {
  return slug.split(/(?=[A-Z])/).join(' ');
};
export const getFileExt = file => {
  let fileArray = file.split('.');
  return fileArray[fileArray.length - 1];
};
export const currentPath = () => {
  let path = window.location.pathname.split('/');
  return `${path[1]}/${path[2]}`;
};
export const currentUrl = () => {
  return `/${currentPath()}`;
};
export const getUrlId = () => {
  let path = window.location.pathname.split('/');
  return path[path.length - 1];
};
export const getPathType = () => {
  let path = window.location.pathname.split('/');
  return path[path.length - 2];
};
export const convertFileSize = fileSize => {
  return (fileSize / 1000000).toFixed(2);
};
export const logoutUser = () => {
  storageRemove(APP_KEY);
  window.location.href = '/';
};
export const getNewDate = date => {
  let d = date.split('-');
  return new Date(d[2], d[1] - 1, d[0]);
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const slugify = text => {
  const trMap = {
    'çÇ': 'c',
    'ğĞ': 'g',
    'şŞ': 's',
    'üÜ': 'u',
    'ıİ': 'i',
    'öÖ': 'o'
  };
  for (let key in trMap) {
    text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
  }
  return text.replace(/[^-a-zA-Z0-9\s]+/ig, '') // remove non-alphanumeric chars
    .replace(/\s/gi, '-') // convert spaces to dashes
    .replace(/[-]+/gi, '-') // trim repeated dashes
    .toLowerCase();
};
export const goAdminUrl = path => {
  window.location.href = strings.PATH.ADMIN + '/' + path;
};
export const goRoot =() => {
  window.location.href =  '/';
};
export const genAdminUrl = path => {
  return strings.PATH.ADMIN + '/' + path;
};
export const StringToMD = (data) => {
  let reVal = data || '';
  reVal = reVal.replace(/###/g, '\n ###');
  reVal = reVal.replace(/\\/g, '\n');
  return reVal;
};

export const byteLength = str => {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (let i = str.length - 1; i >= 0; i--) {
    let code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xDC00 && code <= 0xDFFF) i--; //trail surrogate
  }
  return s;
};
export const listFiller = (list, size) => {
  if (list.length >= size) {
    console.log(list);
    return list;
  } else {
    setTimeout(() => {
      return listFiller(list, size);
    }, 100);
  }
};

export const idSlugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');